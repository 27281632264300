export const extractFieldError = (meta: any, submitting = false) => {
  if (!meta || submitting) {
    return '';
  }

  const { data, error, submitError, touched, dirty, dirtySinceLastSubmit, pristine, active, submitFailed } = meta;

  const showSubmitError = !dirtySinceLastSubmit;
  const showDataError = touched && !dirty;
  const showSynError = submitFailed
    ? pristine || !active // after form was submitted and failed
    : !active && touched; // before form was submitted

  return (showSubmitError && submitError) || (showDataError && data && data.error) || (showSynError && error) || '';
};

// We show form submit error when: fields are pristine since last submit and form is submitting
export const extractFormError = ({ dirtySinceLastSubmit, submitErrors, submitting }: any) =>
  !dirtySinceLastSubmit && !submitting && submitErrors?.message;

export const removeEmojiFromString = (value: string): string => {
  if (!value) {
    return value;
  }

  return value
    .toString()
    ?.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ''
    )
    .trimStart();
};
