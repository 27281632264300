import React, { Fragment, useEffect, useState } from 'react';
import { Field as FinalField } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Col, Divider, Spin } from 'antd';
import { useQuery } from 'react-query';
import addDays from 'date-fns/addDays';
import { Button, Card, CustomIcon, Field, Form, getIsMobile, Text, useHistory, useModal } from '@plandok/core';
import { IntlDate, IntlLabel } from '@plandok/i18n';
import { repeatEndsAfterIntervals, repeatFrequencyIntervals, repeatFrequencyNumbers } from 'constants/data';
import calendarState from 'pages/dashboard/CalendarPage/store/calendar.state';
import { useUserQuery } from 'graphql/queries/userQuery.generated';
import { dateFieldFormatterProps } from 'helpers/date/field';
import { queryResourceOptions, QueryType } from 'api/query';
import { ModifyLayout, AppointmentDate } from 'components';
import { useStore } from 'store/store';
import AppointmentRepeatMobile from './AppointmentRepeatMobile';
import AppointmentFooterTotal from './AppointmentFooterTotal';
import { mutate, mutation } from '../../../../graphql';
import AppointmentField from './AppointmentField';
import ClientSelectMenu from './ClientSelectMenu';
import AddAppointment from './AddAppointment';
import * as helpers from '../helpers';
import * as SC from '../styles';

export default function AppointmentForm(props: any) {
  const { date } = props.initialValues;
  const isMobile = getIsMobile();
  const allowSaveButton = useStore((state) => state.allowSaveButton);
  const setAllowSaveButton = useStore((state) => state.setAllowSaveButton);
  const [, { showModal, hideModal }] = useModal();
  const history = useHistory();

  const [bookings, setBookings] = useState([]);
  const [hasRepeat, setHasRepeat] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [timeFormat, setTimeFormat] = useState('');
  const [recurringOptionsFromState, setRecurringOptionsState] = useState(history?.location?.state);

  const frequency = useStore((state) => state.frequency);
  const setFrequency = useStore((state) => state.setFrequency);
  const repeatingValue = useStore((state) => state.repeatingValue);
  const specificDate = useStore((state) => state.specificDate);
  const findFrequency = repeatFrequencyIntervals.find((interval) => interval.code === frequency)?.name;
  const findRepeatingValue = repeatEndsAfterIntervals.find((interval) => interval.code === repeatingValue)?.name;

  const hasDefaultSpecificDate = parseInt(specificDate) > 30;

  const { data: userData, loading: userLoading } = useUserQuery();
  const isPremium = (!userLoading && userData?.user?.partner?.subscription.premium) ?? false;

  const { data: resourcesOptions, isFetching: isLoadingResources }: any = useQuery(
    [
      QueryType.RESOURCE_OPTIONS,
      props.initialValues?.locationId ? { 'filter[location_id]': props.initialValues?.locationId } : {},
    ],
    queryResourceOptions
  );

  const defaultId = props.defaultEmployeeId || props.initialValues?.bookings?.[0]?.employeeId;

  const onCloseRepeatAppointmentModal = () => hideModal('REPEAT_APPOINTMENT_MODAL');

  const mappedBookings = bookings?.map(({ price, ...rest }: any) => rest);

  const onRepeatSuccess = () => setHasRepeat(true);

  const onRepeatCancel = () => {
    setHasRepeat(false);
    setRecurringOptionsState('');
  };

  const openUpgradeToPremiumModal = () =>
    showModal({
      type: 'UPGRADE_TO_PREMIUM_MODAL',
    });

  const openRepeatAppointmentModal = () =>
    showModal({
      type: 'REPEAT_APPOINTMENT_MODAL',
      modalProps: {
        hasRepeat,
        frequency: findFrequency,
        repeatingValue: findRepeatingValue,
        onSuccess: onRepeatSuccess,
        onExit: onCloseRepeatAppointmentModal,
        openPremium: openUpgradeToPremiumModal,
        recurringOptions: recurringOptionsFromState,
        isPremium,
        isEdit: props.isEdit,
        initialDate: new Date(date || 0),
      },
    });

  useEffect(() => {
    calendarState.initialFetchData().then(() => {
      const { timeFormat } = calendarState.data ?? {};

      setTimeFormat(timeFormat);
    });

    setAllowSaveButton(true);

    if (!recurringOptionsFromState) {
      setFrequency('');
    }
  }, [setAllowSaveButton, recurringOptionsFromState, setFrequency]);

  const findFrequencyNumber = repeatFrequencyNumbers.find((number) => number.code === frequency)?.value || 0;
  const findFrequencyFromState =
    repeatFrequencyNumbers.find((number) => number.code === recurringOptionsFromState?.frequency)?.value || 0;
  const repeatValueFromState = recurringOptionsFromState?.repeating_value || 0;

  const repeatButtonShown = !isMobile && !hasRepeat && !recurringOptionsFromState?.recurrenceId;
  const repeatUntilButtonShown = !isMobile && (hasRepeat || recurringOptionsFromState?.recurrenceId);
  const recurringAppointment = hasRepeat || recurringOptionsFromState?.recurrenceId;
  const untilRepeatingValue = parseInt(repeatingValue) > 1 ? parseInt(repeatingValue) - 1 : parseInt(repeatingValue);
  const untilRepeatValueFromState =
    findFrequencyNumber > 1 ? parseInt(repeatValueFromState) - 1 : parseInt(repeatValueFromState);

  const untilDifference =
    (hasRepeat ? findFrequencyNumber : findFrequencyFromState) *
    (hasRepeat ? untilRepeatingValue : untilRepeatValueFromState);

  const getUntil = (selectedDate: Date) =>
    hasRepeat
      ? addDays(new Date(selectedDate), untilDifference || 0)
      : new Date(recurringOptionsFromState?.last_appointment_date || 0);

  const onRepeatSubmit = async () => {
    await mutate(mutation.CREATE_RECURRING_APPOINTMENT)({
      ...props.initialValues,
      bookings: mappedBookings,
      employeeId: props.defaultEmployeeId || employeeId,
      clientId,
      repeatingType: specificDate ? 'UNTIL_DATE' : 'NUMBER_OF_TIMES',
      repeatingValue,
      frequency,
    });
  };

  const loading = isLoadingResources || !timeFormat;

  return (
    <Spin spinning={loading}>
      <Form
        onSubmit={recurringAppointment ? onRepeatSubmit : props.onSubmit}
        onSuccess={props.onSuccess}
        initialValues={props.initialValues}
        formatForm={helpers.formatAppointmentForm}
        excludeList={props.isEdit ? ['locationId'] : []}
      >
        {({ values, form, formError, submitting, submitFailed }: any) => {
          const isServiceSelected = values?.bookings?.some((booking: any) => booking.serviceId);
          const isServiceFormError = submitFailed && !isServiceSelected;

          if (isServiceSelected) {
            setBookings(values?.bookings);
          }

          if (values.clientId) {
            setClientId(values.clientId);
          }

          const BtnSubmit = (
            <Button block type="primary" loading={submitting} htmlType="submit">
              <Text size="small" weight="bold" colorType="white" mb="none" upperCase>
                <IntlLabel label="appointment.btn.save" />
              </Text>
            </Button>
          );

          let selectedDate: any = values.date;

          const openAppointmentUpdate = () =>
            showModal({
              type: 'UPDATE_APPOINTMENT',
              modalProps: {
                values: {
                  ...values,
                  frequency: frequency || props.location.state.frequency,
                  repeatingValue: repeatingValue || props.location.state.repeating_value,
                  repeatingType: specificDate ? 'UNTIL_DATE' : 'NUMBER_OF_TIMES',
                },
                recurrenceAppointmentData: {
                  bookings: mappedBookings,
                  employeeId: props.defaultEmployeeId || employeeId,
                  clientId,
                  recurrenceId: recurringOptionsFromState?.recurrenceId ?? '',
                  appointmentId: props?.entityId ?? '',
                },
                updateNotRecurringAppointment: props.onSubmit,
                onSuccess: props.onSuccess,
              },
            });

          return (
            <SC.FormContainer>
              <SC.FormRow
                as={Field.Row}
                style={{
                  marginBottom: formError ? '50px' : 0,
                }}
              >
                <Col md={isMobile ? 24 : 15} span={24}>
                  <SC.Card as={Card} minHeight="450px">
                    <Card.Title>
                      <FinalField name="date" component={AppointmentDate as any} {...dateFieldFormatterProps} />
                      {repeatButtonShown && (
                        <SC.RepeatAppButton htmlType="button" onClick={openRepeatAppointmentModal}>
                          <SC.RepeatAppButtonContent>
                            <CustomIcon type="repeat" />
                            <Text size="base" colorType="link" weight="normal" mb="none" isUnderline>
                              <IntlLabel label="rp.repeatAppointment.title" />
                            </Text>
                          </SC.RepeatAppButtonContent>
                        </SC.RepeatAppButton>
                      )}
                      {repeatUntilButtonShown && (
                        <>
                          <SC.RepeatAppButton htmlType="button" onClick={openRepeatAppointmentModal}>
                            <SC.RepeatAppButtonContent>
                              <CustomIcon type="repeat" />
                              <Text size="base" colorType="link" weight="normal" mb="none">
                                <IntlLabel label="rp.repeats.title" /> <IntlLabel label="rp.repeatsUntil.title" />{' '}
                                <IntlDate
                                  date={
                                    specificDate && hasDefaultSpecificDate
                                      ? new Date(specificDate)
                                      : getUntil(selectedDate || 0)
                                  }
                                  dateFormat="EEE, dd MMM yyyy"
                                />
                              </Text>
                            </SC.RepeatAppButtonContent>
                          </SC.RepeatAppButton>
                          <SC.Cancel onClick={onRepeatCancel}>
                            <CustomIcon type="cancelX" />
                          </SC.Cancel>
                        </>
                      )}
                      {isMobile && (
                        <AppointmentRepeatMobile
                          until={getUntil(selectedDate || 0)}
                          frequency={findFrequencyFromState || findFrequency}
                          repeatingValue={recurringOptionsFromState?.repeating_value || repeatingValue}
                          onClick={openRepeatAppointmentModal}
                        />
                      )}
                    </Card.Title>
                    {isMobile && (
                      <Text size="large" weight="semiBold">
                        <IntlLabel label="sidebar.service" />
                      </Text>
                    )}
                    <FieldArray name="bookings">
                      {({ fields }) => (
                        <SC.ScrollableContent>
                          {fields.map((name: string, index: number) => {
                            const removeRow = (fields as any).length > 1 ? () => fields.remove(index) : undefined;

                            if (values?.bookings?.[index]?.employeeId) {
                              setEmployeeId(values?.bookings?.[index]?.employeeId);
                            }

                            return (
                              <Fragment key={index}>
                                {index > 0 && <Divider />}
                                <AppointmentField
                                  name={name}
                                  removeRow={removeRow}
                                  isMobile={isMobile}
                                  changeValue={form.change}
                                  value={(fields.value || [])[index]}
                                  employeeId={values?.bookings?.[index]?.employeeId}
                                  locationId={values?.locationId}
                                  date={selectedDate}
                                  resourcesOptions={resourcesOptions}
                                  isLoadingResources={isLoadingResources}
                                  isServiceFormError={isServiceFormError}
                                  timeFormat={timeFormat}
                                />
                              </Fragment>
                            );
                          })}
                          <Divider orientation="left">
                            <AddAppointment
                              fields={fields}
                              active={helpers.getIsAddAvailable(values)}
                              defaultEmployeeId={defaultId}
                            />
                          </Divider>
                        </SC.ScrollableContent>
                      )}
                    </FieldArray>
                    {isMobile && (
                      <h3>
                        <IntlLabel label="input.notes.label" />
                      </h3>
                    )}
                    <Field.TextArea
                      name="notes"
                      label="input.appointmentNotes.label"
                      placeholder="input.appointmentNotes.placeholder"
                      rows="4"
                    />
                  </SC.Card>
                </Col>
                <SC.RightColumnWrapper as={Col} md={isMobile ? 24 : 8} span={24}>
                  <FinalField name="clientId" component={ClientSelectMenu} />
                  {allowSaveButton && (
                    <ModifyLayout.Footer info={<AppointmentFooterTotal value={values} />} error={formError}>
                      {props.isEdit ? (
                        <>
                          {recurringAppointment ? (
                            <Button block type="primary" onClick={openAppointmentUpdate}>
                              <Text size="small" weight="bold" colorType="white" mb="none" upperCase>
                                <IntlLabel label="appointment.btn.save" />
                              </Text>
                            </Button>
                          ) : (
                            BtnSubmit
                          )}
                        </>
                      ) : (
                        BtnSubmit
                      )}
                    </ModifyLayout.Footer>
                  )}
                </SC.RightColumnWrapper>
              </SC.FormRow>
            </SC.FormContainer>
          );
        }}
      </Form>
    </Spin>
  );
}
