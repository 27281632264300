import { message } from 'antd';
import { LoginFormProps } from 'pages/auth/LoginPage/components/LoginForm';
import { useGoogleLogin } from '@react-oauth/google';
import React, { useState } from 'react';
import { Button, CustomIcon, Text } from '@plandok/core';
import * as api from 'api';

function GoogleLoginButton(props: LoginFormProps) {
  let [loading, setLoading] = useState(false);

  const googleSignIn = useGoogleLogin({
    onSuccess: async (codeResponse: any) => {
      setLoading(true);
      let response = await api.socialAuthLogin('google')({
        accessToken: codeResponse.code,
      });
      setLoading(false);

      if (response?.token) {
        props.onSuccess(response);
        return;
      }

      message.error(response?.errors?.message);
    },
    onError: (error: any) => message.error('Login failed'),
    flow: 'auth-code',
    scope: 'openid email profile',
  });

  return (
    <Button
      whiteBtn
      className="justify-align-center w-100 mt-4"
      upperCase={false}
      onClick={googleSignIn}
      loading={loading}
    >
      <CustomIcon type="googleLogin" />
      <Text label="btn.continueWithGoogle.title" mb="none" colorType="black" size="base" weight="medium" ml="xsmall" />
    </Button>
  );
}

export default GoogleLoginButton;
