import ReactDatePicker from 'react-datepicker';
import { getDay, getMonth } from 'date-fns';
import React, { useContext } from 'react';
import { Icon } from 'antd';
import { getIsMobile, monthOptions, startWeekOptions } from '@plandok/core';
import { IntlDate, IntlLabel, ReactIntl } from '@plandok/i18n';
import { DatepickerHeader } from 'components';
// import * as SC from '../AppointmentSpecificDate/styles';

export default function AppointmentDate({ input, hideArrow, withPortal = getIsMobile(), disabled }: any) {
  const { lang } = useContext(ReactIntl);

  const weekDay = startWeekOptions.find(({ code }) => code === getDay(input.value))?.name;
  const month = monthOptions.find(({ code }) => code === getMonth(input.value))?.name;
  const date = input.value || new Date();

  return (
    <ReactDatePicker
      renderCustomHeader={DatepickerHeader}
      withPortal={withPortal}
      customInput={
        <>
          <span>
            <Icon type="calendar" />
          </span>

          <span>
            <IntlLabel label={weekDay} />
            <IntlDate date={date} dateFormat=", dd " />
            <IntlLabel label={month} /> <IntlDate date={date} dateFormat=" yyyy" />
          </span>

          <span>{!hideArrow && <Icon type="down" />}</span>
        </>
      }
      selected={input.value}
      disabled={disabled}
      locale={lang}
      {...input}
    />
  );
}
