import { Col, message, Row, Divider } from 'antd';
import CalendarSync from 'components/app-items/CalendarSync';
import React from 'react';
import { Button, Card, Field, Form, Text, useModal, validatePassword, validateRequired } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { passwordTooltip } from 'constants/form/general';
import * as SC from './styles';

export default function PersonalSettingForm(props: any) {
  const [, { showModal }] = useModal();

  const onSuccess = () => message.success(<IntlLabel label="notification.success.update" />);

  const viewDeleteAccountModal = () => showModal({ type: 'DELETE_ACCOUNT_MODAL' });

  return (
    <Form
      validate={(values: any) => {
        if (values.newPassword !== values.verifyPassword) {
          return { verifyPassword: 'validation.password.match' };
        }
      }}
      excludeList={['verifyPassword', 'email']}
      combinedFields={['phoneNumber']}
      {...props}
      onSuccess={onSuccess}
    >
      {({ submitting, formError, values }: any) => {
        const hasPasswordValidation = !!values?.currentPassword || !!values?.newPassword || !!values?.verifyPassword;

        return (
          <>
            <Card>
              <Row gutter={30}>
                <Col span={24} md={12}>
                  <Text label="title.personalDetails" bold />
                  <Text label="title.personalDetails.description" size="base" />
                  <br className="hidden-md" />
                  <Field.Row>
                    <Field.Input
                      name="firstName"
                      label="input.firstName.label"
                      placeholder="input.firstName.placeholder"
                      md={12}
                      validate={validateRequired}
                    />
                    <Field.Input
                      name="lastName"
                      label="input.lastName.label"
                      placeholder="input.lastName.placeholder"
                      md={12}
                    />
                    <Field.Input
                      name="email"
                      label="input.email.label"
                      placeholder="input.email.placeholder"
                      disabled
                      md={24}
                    />
                    <Field.CombinedInput
                      name="phoneNumber"
                      label="input.mobile.label"
                      placeholder="input.mobile.placeholder"
                    />
                  </Field.Row>
                </Col>
                <Col span={24} md={12}>
                  <Text label="title.changePassword" bold />
                  <Text label="title.changePassword.description" size="base" />
                  <Field.Input
                    name="currentPassword"
                    label="input.currentPassword.label"
                    placeholder="input.password.placeholder"
                    type="password"
                    validate={hasPasswordValidation ? validateRequired : undefined}
                  />
                  <Field.Input
                    name="newPassword"
                    tooltip={passwordTooltip}
                    label="input.password.label"
                    placeholder="input.password.placeholder"
                    type="password"
                    validate={hasPasswordValidation ? validatePassword : undefined}
                  />
                  <Field.Input
                    name="verifyPassword"
                    label="input.verifyPassword.label"
                    placeholder="input.verifyPassword.placeholder"
                    type="password"
                    validate={hasPasswordValidation ? validateRequired : undefined}
                  />
                </Col>
                <SC.Container>
                  <Divider className="mt-0" />
                  <CalendarSync />
                  <SC.DeleteYourAccountWrapper onClick={viewDeleteAccountModal}>
                    <Text label="btn.deleteYourAccount" mb="none" size="base" />
                  </SC.DeleteYourAccountWrapper>
                </SC.Container>
              </Row>
            </Card>
            {formError && (
              <Row type="flex" justify="center" className="mt-1 mb-1">
                <Form.Alert text={formError} />
              </Row>
            )}
            <Row type="flex" justify="center" className="mt-2 mb-2">
              <Button
                label="btn.save.changes"
                type="primary"
                className="big-btn-padding"
                bold
                upperCase
                htmlType="submit"
                loading={submitting}
              />
            </Row>
          </>
        );
      }}
    </Form>
  );
}
