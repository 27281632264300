import React from 'react';
import { Button, CustomIcon, Text } from '@plandok/core';
import * as SC from '../styles';

type CalendarItemComponentProps = {
  icon: 'googleIcon' | 'apple' | 'microsoft365' | 'myLink';
  title: string;
  btn: string;
  disabled: boolean;
  action: () => void;
  isConnected?: boolean;
};

export default function CalendarItemComponent({
  icon,
  title,
  btn,
  disabled,
  action,
  isConnected,
}: CalendarItemComponentProps) {
  return (
    <SC.CalendarItemContainer>
      <div className="align-center">
        <CustomIcon type={icon} />
        <Text label={title} mb="none" ml="small" size="base" weight="medium" />
        {isConnected && (
          <SC.ConnectedContainer>
            <CustomIcon type="checkmark" />
            <Text label="connected.label" mb="none" ml="xxsmall" weight="medium" />
          </SC.ConnectedContainer>
        )}
      </div>
      <Button
        label={btn}
        upperCase={false}
        whiteBtn
        semiBold
        dangerTextBtn={isConnected}
        disabled={disabled}
        onClick={action}
      />
    </SC.CalendarItemContainer>
  );
}
