import { callAction, callActionWithId } from '@plandok/core';

export const rescheduleMeeting = ({
  appointmentId,
  bookingId,
  ...props
}: {
  appointmentId: string;
  bookingId: string;
  employeeId: string;
  date: string;
  startTime: number;
  duration: 0;
}) => callAction(`/appointments/${appointmentId}/bookings/${bookingId}/reschedule` as any, 'post')(props);

export const rescheduleBlockTime = callActionWithId('/blocked-times/{blockedTimeId}', 'put');

export const fetchCalendarSyncs = callAction('/calendar-syncs', 'get', true);
export const connectCalendarSyncs = callActionWithId('/calendar-sync/{id}/connect', 'post', true);
export const disconnectCalendarSyncs = callActionWithId('/calendar-sync/{id}/disconnect', 'post', true);
