import { mediaMdDown } from '@plandok/core';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 0 30px 0 15px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  button {
    box-shadow: 0px 4px 12px -3px #263c7a33;
  }

  ${mediaMdDown(css`
    padding: 20px 0;
    button {
      font-size: 14px;
    }
  `)}
`;

export const ButtonsWrapper = styled.div`
  margin-left: 12px;

  div {
    :nth-child(1) {
      line-height: 20px;
    }
    :nth-child(2) {
      line-height: 16px;
    }
  }
`;

export const DeleteYourAccountWrapper = styled.div`
  max-width: max-content;

  :hover {
    cursor: pointer;
  }
  div {
    color: #ff5e5e;
    line-height: 19px;
  }

  ${mediaMdDown(css`
    margin: 0 auto;
  `)}
`;
