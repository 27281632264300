import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { CustomIcon, Text, getIsMobile } from '@plandok/core';
import * as SC from './styles';

const checkEndPoint = `${process.env.PUBLIC_URL}/index.html`;

export default function NoInternetConnection({ children }: PropsWithChildren<{ children: JSX.Element }>) {
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const isMobile = getIsMobile();
  const routineRef = useRef<any>();

  const updateAvailability = async () => {
    try {
      const online = await fetch(`${checkEndPoint}`);
      setIsOnline(() => {
        return online.status >= 200 || online.status <= 300;
      });
    } catch (e) {
      setIsOnline(false);
    }
  };

  const onNotificationHide = () => setIsOnline(true);

  useEffect(() => {
    routineRef.current = setInterval(async () => {
      await updateAvailability();
    }, 20000);

    return () => clearInterval(routineRef.current);
  }, []);

  return (
    <>
      {children}

      {!isOnline && (
        <SC.Container isMobile={isMobile}>
          <CustomIcon type="infoCircle" />
          <Text
            mb="none"
            label="noInternetConnection.notification.title"
            colorType="white"
            weight="medium"
            size={isMobile ? 'small' : 'base'}
            ml="xsmall"
          />
          <SC.BtnClose onClick={onNotificationHide} label="X" isMobile={isMobile} semiBold />
        </SC.Container>
      )}
    </>
  );
}
