import React from 'react';
import { withModal, Button, Text } from '@plandok/core';
import { RoutePath } from 'constants/routes';
import { useLocation } from 'hooks/router';

type UpgradeToPremiumModalProps = {
  onClose: () => void;
  hideAllModals: () => void;
};

function UpgradeToPremiumModal({ onClose, hideAllModals }: UpgradeToPremiumModalProps) {
  const { navigate } = useLocation();

  const goToPricingPage = () => {
    hideAllModals();
    navigate(RoutePath.BILLING);
  };

  return (
    <>
      <Text colorType="base" isCenter label="rp.upgrade.description.title" />

      <Button type="primary" onClick={goToPricingPage}>
        <Text colorType="white" weight="semiBold" size="base" mb="none" upperCase label="rp.upgrade.button.title" />
      </Button>

      <Button type="ghost" upperCase={false} onClick={onClose}>
        <Text colorType="base" weight="semiBold" size="base" mb="none" label="btn.close" />
      </Button>
    </>
  );
}

export default withModal('rp.upgrade.title', { contentClassName: 'upgrade-to-premium-modal' })(UpgradeToPremiumModal);
