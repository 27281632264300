import React from 'react';
import { Button, Field, Form, composeValidators, validateEmail, validateRequired } from '@plandok/core';

export interface LoginFormProps {
  onSubmit?: any;
  onSuccess?: any;
}

export default function LoginForm(props: LoginFormProps) {
  return (
    <Form onSubmit={props.onSubmit} onSuccess={props.onSuccess}>
      {({ submitting, formError }: any) => (
        <>
          <Field.Input
            name="email"
            label="input.email.label"
            placeholder="input.email.placeholder"
            validate={composeValidators(validateRequired, validateEmail)}
            submitting={submitting}
          />
          <Field.Input
            name="password"
            className="password-no-suffix"
            label="input.password.label"
            placeholder="input.password.placeholder"
            type="password"
            validate={validateRequired}
            submitting={submitting}
          />
          <Form.Alert text={formError} />
          <Button type="primary" block htmlType="submit" loading={submitting} label="login.btn" />
        </>
      )}
    </Form>
  );
}
